<template>
  <div class="bookingSignForm">
    <div class="bookingSignForm__subTitle">{{ waiver.intro }}</div>
    <div
      v-if="waiver.text"
      class="bookingSignForm__text"
      v-html="sanitize(waiver.text)"
    />
    <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(handleFormSubmit)">
        <Input
          v-if="registrationCode"
          :value="registrationCode"
          is-disabled
          placeholder="Registration code"
          class="mb-24"
        />
        <CustomFieldsInput v-model="fields" />
        <div class="grid-2">
          <FormItem rules="required">
            <signature-with-validation
              class="bookingSignForm__signature"
              :value="signature"
              width="100%"
              @input="signature = $event"
            />
          </FormItem>
        </div>
        <div v-if="checkboxes.length" class="bookingSignForm__checkboxes">
          <FormItem
            v-for="(checkbox, index) in checkboxes"
            :key="index"
            :rules="checkbox.isRequired ? 'required|checkbox' : undefined"
          >
            <Checkbox v-model="checkbox.value">
              <span v-html="sanitize(checkbox.text)" />
            </Checkbox>
          </FormItem>
        </div>
        <div class="bookingSignForm__button">
          <Button
            type="submit"
            variant="primary"
            :is-block="['xs', 'sm'].includes($mq)"
            :is-disabled="invalid"
            :is-loading="isLoading"
          >
            {{ $t("Sign") }}
          </Button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import SignatureWithValidation from "@/components/signatures/SignatureWithValidation";
import { sanitize } from "@/helpers/utils";
import CustomFieldsInput from "@/components/common/customFields/CustomFieldsInput";

export default {
  name: "BookingSignForm",
  components: {
    CustomFieldsInput,
    ValidationObserver,
    SignatureWithValidation,
  },
  props: {
    waiver: {
      type: Object,
      required: true,
    },
    registrationCode: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    fields: {},
    checkboxes: [],
    signature: "",
  }),
  computed: {
    sortedFields() {
      return Object.entries(this.fields)
        .map(([key, value]) => ({
          key,
          ...value,
        }))
        .sort((a, b) => a.sortWeight - b.sortWeight);
    },
  },
  mounted() {
    this.fields = JSON.parse(JSON.stringify(this.waiver.fields));
    this.checkboxes = JSON.parse(JSON.stringify(this.waiver.checkboxes));
  },
  methods: {
    sanitize,
    async handleFormSubmit() {
      this.$emit("submit", {
        fields: this.fields,
        checkboxes: this.checkboxes,
        signature: this.signature,
      });
    },
  },
};
</script>

<style lang="scss">
.bookingSignForm {
  width: 100%;

  &__subTitle {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $secondary-500;
  }

  &__text {
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    white-space: pre-wrap;
  }

  &__signature {
    width: 100%;
  }

  &__checkboxes {
    margin: 64px 0 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__button {
    margin-top: 24px;
    @media (min-width: 1024px) {
      min-width: 256px;
    }
  }
}
</style>
