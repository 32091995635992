<template>
  <signature
    v-bind="$attrs"
    @signature="handleSignatureSave"
    @clear="handleSignatureClear"
  />
</template>
<script>
export default {
  name: "SignatureWithValidation",
  props: {
    value: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    innerValue(val) {
      this.$emit("input", val);
    },
  },
  methods: {
    handleSignatureSave(signature) {
      this.innerValue = signature;
    },
    handleSignatureClear() {
      this.innerValue = "";
      this.$emit("clear");
    },
  },
};
</script>
