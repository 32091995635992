<template>
  <div class="bookingSignaturesSignPage">
    <Loader v-if="isLoading" color="primary" size="m" class="mx-auto" />
    <p v-else-if="isError" class="empty">
      {{ $t("An error occurred") }}. {{ $t("repeat the request later") }}
    </p>
    <template v-else>
      <back-title confirm-click @click="goBack">
        {{ `${$t("Sign")} ${booking.activeWaiver.title}` }}
      </back-title>
      <booking-sign-form
        :registrationCode="booking.registrationCode"
        :waiver="booking.activeWaiver"
        :is-loading="isCreating"
        @submit="handleSubmit"
      />
    </template>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import { mapActions, mapState } from "vuex";
import BookingSignForm from "@/components/bookings/BookingSignForm";
import dialog from "@/plugins/dialog";

export default {
  name: "BookingSignaturesSignPage",
  components: { BookingSignForm, BackTitle },
  data: () => ({
    isLoading: false,
    isCreating: false,
    isError: false,
  }),
  computed: {
    ...mapState({
      booking: (state) => state.bookings.editedItem,
      product: (state) => state.products.selectedProduct,
    }),
  },
  async created() {
    try {
      this.isLoading = true;
      if (!this.booking) {
        await this.getBooking(this.$route.params.id);
      }
    } catch {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      getBooking: "bookings/fetchBookingById",
      createSignature: "signatures/createSignature",
      uploadMedia: "signatures/uploadMedia",
    }),
    goBack() {
      this.$router.push({
        name: "BookingSignatures",
      });
    },
    async handleSubmit(data) {
      this.isCreating = true;
      try {
        const { url } = await this.uploadMedia({
          dataUrl: data.signature,
          scope: "signatures",
        });
        data.signature = url;
        await this.createSignature({
          waiverId: this.product.activeWaiverId,
          bookingReference: this.booking.registrationCode,
          ...data,
        });
        this.goBack();
        await dialog.alert({
          title: this.$t("Waiver successfully signed"),
          okText: "Done",
        });
      } finally {
        this.isCreating = false;
      }
    },
  },
};
</script>
<style lang="scss">
.bookingSignaturesSignPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  height: 100%;
}
</style>
